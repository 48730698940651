<template>
  <div class="grid">
    <CrudTable ref="issue" :entities="entities"
      :meta="metaTable"
      @paging="onPaging"
      @tableAction="onTableAction"
      @itemAction="onItemAction"
      @cell-edit-complete="onCellEditComplete"
      @rowExpand="onRowExpand"
      @filter="onFilter"       
      @sort="onSort"
      @subtable-item-action="onSubtableItemAction"/>
  </div>
  <Dialog v-model:visible="showMessageDialog">
    <template #header>
      <h3>{{$t('issue.editMessage')}}</h3>
    </template>
    <Textarea v-model="message" rows="5" cols="40" />
    <template #footer>
      <Button :label="$t('button.save')" icon="pi pi-save" @click="onSaveMessage" autofocus />
    </template>
  </Dialog>
  <Dialog v-model:visible="showImagesDialog">
    <template #header>
      <h3>{{$t('issue.images')}}</h3>
    </template>
    <Galleria :value="selectedImages" :responsiveOptions="responsiveOptions" :numVisible="5" :circular="true" containerStyle="max-width: 640px"
      :showItemNavigators="true">
      <template #item="slotProps">
          <img :src="slotProps.item.imageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block;" />
      </template>
      <template #thumbnail="slotProps">
          <img :src="slotProps.item.thumbnailSrc" :alt="slotProps.item.alt" style="display: block;" />
      </template>
    </Galleria>
  </Dialog>
</template>
<script>
import { shallowRef, defineAsyncComponent } from "vue";
import CrudTable from '@/pages/common/CrudTable';
import {serializeOrders } from '@/utils/params';
import { Consts } from '@/utils/consts';
import IssueService from '@/service/IssueService';
import formatter from '@/utils/formatter';
import mixin_list from '@/mixins/list';
import {Service} from '@/service/common';

export default {
  components: {CrudTable,
    //Conversation: defineAsyncComponent(() => import("./Conversation"))
  },
  mixins:[mixin_list],
  data() {
    const metaTable = {
      actionsHeaderStyle: "width:12rem; text-align: center;",
      entityName: "issue",
      searchable: true,
      expander:false,
      subComponent: shallowRef(defineAsyncComponent(() => import("./Conversation"))),
      sortMode: "multiple",
      multiSortMeta: [{field:"createdDate", order: -1}],
      paginator:{
        enable: true,
        rows: Consts.DEFAULT_PAGE_ROWS,
        rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
        currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} issues",
      },
      filter: {
        filterDisplay: "menu",
        globalFilterFields: ['shipmentCode','receiptCode','packageCode']
      },
      columns: [
        {
          name: "createdDate",
          label:"common.createdDate",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          dataType:"date",
          formatter: formatter.formatDate
        },
        {
          name: "partnerCode",
          label:"issue.partnerCode",
          headerStyle: "width:10%; min-width:5rem;",
          filter: true,
          condition:"user.orgType=='owner'"
        },
        {
          name: "shipmentCode",
          label: "issue.shipmentCode",
          headerStyle: "width:12%; min-width:8rem;",
          filter: true,
        },
        /*
        {
          name: "receiptCode",
          label:"receipt.receiptCode",
          headerStyle: "width:10%; min-width:5rem;",
          filter: true
        },
        */
        {
          name: "packageCode",
          label: "package.packageCode",
          headerStyle: "width:12%; min-width:5rem;",
          filter: true,
          link: this.openPackageDetail
        },
        {
          name: "status",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
        },
        {
          name: "description",
          headerStyle: "min-width:10rem;",
          filter: true,
        }
      ],
      tableActions: [
        {
          name: "filterclear",
          icon: "pi pi-filter-slash",
          style: "p-button-outlined mr-2"
        },
        {
          name: "create",
          icon: "pi pi-pencil",
          style: "p-button mr-2"
        },
      ],
      itemActions:[
        {
          name: "viewDetail",
          icon: "pi pi-search",
          style: "p-button mr-2",
          //condition: "status == 'opened'"
        },
        {
          name: "showImages",
          icon: "pi pi-images",
          style: "p-button mr-2"
        },
        {
          name: "close",
          icon: "pi pi-thumbs-up",
          style: "p-button mr-2"
        },

      ],
      metaSubTable: {
        entityName: "issueMessage",
        dataField: "messages",
        paginator:{
          enable: true,
          rows: Consts.DEFAULT_PAGE_ROWS,
          rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
        },
        columns: [
          {
            name: "createdDate",
            label:"common.createdDate",
            headerStyle: "width:15%; min-width:10rem;",
            dataType:"date",
            formatter: formatter.formatDate
          },
          {
            name: "createdUser",
            headerStyle: "width:15%; min-width:10rem;"
          },
          {
            name: "message",
            headerStyle: "min-width:10rem;"
          },

        ],
        tableActions: [

        ],
        itemActions: [
          {
            name: "delete",
            icon: "pi pi-trash",
            style: "p-button mr-2"
          },
        ]
      }
    }
    return {
      entities: [],
      filterOptions: {
        page: 0,
        size: Consts.DEFAULT_PAGE_ROWS
      },
      selectedImages: [],
      selectedEntity: null,
      message:null,
      currentUser: null,
      showMessageDialog: false,
      showImagesDialog: false,
      metaTable : metaTable
    }
  },
  created() {
    this.messageService = new Service('issueMessage');
  },
  mounted() {
    this.filterOptions.sorts = serializeOrders({multiSortMeta: this.metaTable.multiSortMeta});
    this._loadEntities();
  },
  methods: {
    _loadEntities() {
      IssueService.getAll(this.filterOptions).then(data => {
				if(Array.isArray(data.content)) {
          this.entities = data.content;
          this.metaTable.paginator.totalRecords = data.totalElements;
        } else {
          this.entities = [];
          this.metaTable.paginator.totalRecords = 0;
        }
			})
      // .finally(() => {
      //   this.timeoutHandler = setTimeout(this._loadShipments, this.timeout);
      // });
    },
    onSaveMessage() {
      IssueService.saveMessage(this.selectedEntity.id, this.message).then(res => {
        console.log(res);
        this.selectedEntity.messages = this.selectedEntity.messages || [];
        this.selectedEntity.messages.push(res);
        this.showMessageDialog = false;
      });
    },
    onTableAction(sAction) {
      if (sAction == 'create') {
        this.$router.push({ name: 'users_issue_edit', params: {} });
      }
      console.log(sAction);
    },
    onItemAction(sAction, entity) {
      switch(sAction) {
        case 'addMessage':
          console.log(entity);
          this.selectedEntity = entity;
          this.showMessageDialog = true;
          break;
        case 'viewDetail':
          this.selectedEntity = entity;
          this.$router.push({ name: 'users_issue_detail', params: {id: entity.id} })
          break;
        case 'close':
          this.selectedEntity = entity;
          this.selectedEntity.status = 'closed';
          IssueService.save(this.selectedEntity).then(() => {});
          break;
        case 'showImages':
          this.selectedEntity = entity;
          IssueService.loadImages(this.selectedEntity).then((images) => {
            this.selectedImages = images;
            this.showImagesDialog = true;
          });
          break;
      }
    },
    openPackageDetail(data) {
      console.log(data);
      this.$router.push({ name: 'user_package_infos', params: {id: data.id} })
    },
    onCellEditComplete() {

    },
    onRowExpand(event) {
      this.selectedEntity = event.data;
    },
    onSubtableItemAction(sAction, item) {
      if(sAction == 'delete') {
        this.messageService.delete(item.id).then((res) => {
          for(var i = 0; i < this.selectedEntity.messages.length; i++){
            if (this.selectedEntity.messages[i].id === res.id) {
                this.selectedEntity.messages.splice(i, 1);
                break;
            }
          }
        })
      }
    }
  }
}
</script>
